<template>
    <div>
        <div style="height: 100vh; background-color: #fff;">
            <div class="top">
                <img class="mySp" :src="require('@/assets/home/wj_bq_wdkh.png')" alt="">
                <div class="title">{{ data.Assessment_Name }}</div>
                <div class="info">
                    <span>考核时间：{{ $moment(data.Answer_Start_Time).format('YYYY-MM-DD') }}~{{
                    $moment(data.Answer_End_Time).format('YYYY-MM-DD') }}</span>
                    <span>发起人：{{ data.Publish_User_Name }}</span>
                </div>
                <div class="plan">
                    <div class="score">
                        <div class="num" style="font-size: 14px;">{{
                    data.AssessmentType == 1 ? '自评+考核评' : data.AssessmentType == 2 ? '仅自评' : '考核评' }}</div>
                        <div class="tit">考核方式</div>
                    </div>
                    <div class="score">
                        <div class="num">{{ data.ComplateCount }}</div>
                        <div class="tit">已评分</div>
                    </div>
                    <div class="score">
                        <div class="num">{{ data.AllCount - data.ComplateCount }}</div>
                        <div class="tit">未评分</div>
                    </div>
                    <div class="per">
                        <div class="num">
                            {{ ((data.ComplateCount / data.AllCount) * 100).toFixed(0) + "%" }}
                        </div>
                        <div class="tit">进度</div>
                    </div>
                </div>
            </div>
            <div class="tips">选择老师</div>
            <div class="center">
                <div class="item" v-for="(item, index) in data.teacherStates" :key="index" @click="goEdit(item)">
                    <div class="imgBox">
                        <img :src="require('@/assets/home/tx_ls.png')" alt="">
                        <div class="name">{{ item.TeacherName }}</div>
                    </div>
                    <div class="" style="text-align: center; font-size: 12px;"
                        v-if="data.AssessmentType == 1 || data.AssessmentType == 2">
                        <div>自评分</div>
                        <div style="color:#0080ee; font-size: 14px;">{{ item.Self_All_Score }}</div>
                    </div>
                    <div class="" style="text-align: center; font-size: 12px;"
                        v-if="data.AssessmentType == 1 || data.AssessmentType == 3">
                        <div>考核评分</div>
                        <div style="color:#0080ee; font-size: 14px;">{{ item.Man_All_Score }}</div>
                    </div>
                    <div :class="item.IsComplete ? 'yes' : 'not'">
                        {{ item.IsComplete ? '已评分' : '未评分' }}<van-icon name="arrow" />
                    </div>
                </div>
            </div>

            <div class="btn"><van-button type="info" @click="goMark" :disabled="new Date(data.Answer_End_Time)>= new Date()?false:true"> <van-icon name="edit" />前往考核</van-button></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {},
        }
    },
    mounted() {
        this.getTeacher()
    },
    methods: {
        getTeacher() {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/PFM/PfmRecord/GetAssessmentTeachers?guid=" + self.$route.query.guid,
                completed(m) {
                    console.log(m)
                    self.data = m.DATA
                },
            });
        },
        goMark() {
            sessionStorage.setItem('markData', JSON.stringify(this.data))
            this.$router.push({ path: '/check/mark', query: { guid: this.$route.query.guid } })
        },
        goEdit(item) {
            sessionStorage.setItem('markData', JSON.stringify(this.data))
            this.$router.push({ path: '/check/mark', query: { teacherId: item.TeacherId, guid: this.$route.query.guid } })
        }
    },
}
</script>
<style lang="less" scoped>
.top {
    position: relative;
    background: #fff;
    padding-top: 10px;

    .mySp {
        position: absolute;
        top: 0;
        left: 0;
        width: 64px;
        height: 23px;
    }

    .title {
        width: 80%;
        margin: 20px auto 0;
        color: #0080ee;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        border-bottom: 1px dashed #cfcfcf;
        padding-bottom: 20px;
    }

    .info {
        color: #98a3ad;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        font-size: 14px;
        padding: 10px;
    }

    .plan {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 14px;
        padding: 10px 0;

        .score {
            .num {
                color: #0080ee;
                text-align: center;
                font-weight: bold;
                font-size: 18px;
            }

            .tit {
                color: #98a3ad;
            }
        }

        .per {
            .num {
                color: #ff7c64;
                text-align: center;
                font-weight: bold;
                font-size: 18px;
            }

            .tit {
                color: #98a3ad;
            }
        }
    }
}

.tips {
    color: #98a3ad;
    text-align: center;
    padding: 10px 0;
    background: #f4f8fb;
}

.center {
    background: #fff;
    padding: 10px 10px 70px;

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f3f3f3;
        padding-top: 10px;
        padding-bottom: 10px;

        .imgBox {
            display: flex;
            align-items: center;

            img {
                width: 45px;
                height: 45px;
                display: inline-block;
                margin-right: 5px;
            }

            .name {
                font-weight: bold;
                color: #222;
            }
        }

        .yes {
            color: #0195ff;
            border: 1px solid #80caff;
            border-radius: 50px;
            font-size: 12px;
            padding: 4px 10px;
        }

        .not {
            color: #a3b1bc;
            border: 1px solid #d1d8dd;
            border-radius: 50px;
            font-size: 12px;
            padding: 4px 10px;
        }
    }
}

.btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 40px;
    text-align: center;
    padding: 10px 0;

    button {
        width: 90%;
        font-size: 16px;
        border-radius: 10px;
    }
}
</style>